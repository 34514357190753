import { ChangeDetectorRef, Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { IndividualsService } from '../../../individuals.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountrySettingService } from 'src/app/core/countries-managment/country-settings.service';
import { MaritalStatusList } from '../data-individual';
import { documentTypeByCountry } from 'src/app/modules/shared/document-types/documentTypeConfig';

@Component({
  selector: 'app-individual-form',
  templateUrl: './individual-form.component.html',
  styleUrls: ['./individual-form.component.scss']
})
export class IndividualFormComponent implements OnInit, OnChanges {
  prefixes = [];

  @Input() IndividualType;
  @Input() individualData;
  countryCode;
  @Output() updateDataForm: EventEmitter<any> = new EventEmitter();


  dataFormGroup: FormGroup;
  countriesList = [];
  maritalStatusList = MaritalStatusList;
  documentTypesList = documentTypeByCountry.AR;

  constructor(
    private individualsService: IndividualsService,
    private _formBuilder: FormBuilder,
    private ref: ChangeDetectorRef,
    private countySettingService: CountrySettingService) { }

  ngOnInit(): void {
    this.getPrefixesList();
    this.getCountriesList();
  }

  ngOnChanges() {
    this.countySettingService.setCurrentCountryID(this.individualData?.countries_id);
    this.countryCode = this.countySettingService.getCurrentCountryCode();
    this.documentTypesList = documentTypeByCountry[this.countryCode];

    if (this.IndividualType === 0) {
      this.dataFormGroup = this._formBuilder.group({
        countries_id: [(this.individualData?.countries_id) ? this.individualData?.countries_id : 11],
        name: [this.individualData?.name, Validators.required],
        last_name: [this.individualData?.last_name, Validators.required],
        email: [this.individualData?.email],
        phone: [this.individualData?.phone],
        document_type: [this.individualData?.document_type],
        document: [this.individualData?.document],
        cuit: [this.individualData?.cuit],
        birthday: [this.individualData?.birthday],
        occupation: [this.individualData?.occupation],
        nationality: [this.individualData?.nationality],
        marital_status: [this.individualData?.marital_status],
        individual_prefixes_id: [this.individualData?.individual_prefixes_id]
      });

      this.dataFormGroup.valueChanges.subscribe(value => {
        Object.keys(value).forEach(key => {
          if (!value[key]) delete value[key];
        });

        value.fullname = `${value.name ? value.name : ''} ${ value.last_name ? value.last_name : ''}`;
        
        if (value.name && value.last_name) value.initials = value.name[0] + value.last_name[0];
        value.description = value.email;

        if (value.birthday) {
          value.birthday = new Date(value.birthday);
          value.birthday = value.birthday.toISOString();
        } else {
          delete value.birthday;
        }

        this.countySettingService.setCurrentCountryID(value.countries_id);
        this.countryCode = this.countySettingService.getCurrentCountryCode();
        this.documentTypesList = documentTypeByCountry[this.countryCode];

        this.updateDataForm.emit({
          invalid: this.dataFormGroup.invalid,
          value: value
        });
      });

    };
    if (this.IndividualType === 1) {
      this.dataFormGroup = this._formBuilder.group({
        countries_id: [(this.individualData?.countries_id) ? this.individualData?.countries_id : 11 , Validators.required],
        name: [this.individualData?.name, Validators.required],
        jurisdiction: [this.individualData?.jurisdiction],
        nationality: [this.individualData?.nationality],
        legal_representative: [this.individualData?.legal_representative],
        cuit: [this.individualData?.cuit],
      });

      this.dataFormGroup.valueChanges.subscribe(value => {
        Object.keys(value).forEach(key => {
          if (!value[key]) delete value[key];
        });
        value.fullname = value.name;
        if (value.name?.length > 1) value.initials = value.name[0] + value.name[1];
        if (value.countries_id) value.description = 'COUNTRIES.ID' + value.countries_id;

        this.countySettingService.setCurrentCountryID(value.countries_id);
        this.countryCode = this.countySettingService.getCurrentCountryCode();

        this.updateDataForm.emit({
          invalid: this.dataFormGroup.invalid,
          value: value
        });
      });
    };
  }

  getPrefixesList() {
    this.individualsService.getPrefixes().subscribe((res) => {
      this.prefixes = res;
      this.ref.markForCheck();
    });
  }

  getCountriesList() {
    this.individualsService.getCountries().subscribe((res) => {
      this.countriesList = res;
      this.ref.markForCheck();
    });
  }
}
