import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from 'src/app/core/services/rest.service';

@Injectable({
  providedIn: 'root',
})
export class IndividualsService {
  constructor(private restService: RestService) {}

  createIndividual(data) {
    return this.restService.post_api('individuals', data).pipe(
      map((res) => {
        if (res.success) {
          return res.result.id;
        } else {
          return null;
        }
      })
    );
  }

  updateIndividual(id, data) {
    return this.restService.put_api(`individuals/update/${id}`, data);
  }

  getIndividualInfo(id) {
    return this.restService.get_api(`individuals/${id}`).pipe(
      map((res) => {
        if (res.success) {
          let response = res.result;
          let fullname = `${response.name} ${
            response.last_name ? response.last_name : ''
          }`;

          if (response.type_id === 1) {
            fullname = `${response.name}`;
          }

          response.links.stock_companies.forEach((e) => {
            e.stocks.forEach((i) => {
              i.fullname = fullname;
            });
          });

          response.links.authorities_companies.forEach((e) => {
            e.authorities.forEach((i) => {
              i.fullname = fullname;
            });
          });

          let initials;

          if (response.type_id === 1) {
            initials = `${response.name[0]} ${
              response.name[1] ? response.name[1] : ''
            }`;
          } else {
            initials = `${response.name[0]} ${
              response.last_name ? response.last_name[0] : response.name[1]
            }`;
          }

          return {
            ...response,
            fullname: fullname,
            initials: initials,
            description:
              response.type_id === 0
                ? response.email
                : 'COUNTRIES.ID' + response.countries_id,
          };
        } else {
          return null;
        }
      })
    );
  }

  getPrefixes() {
    return this.restService.get_api('individualprefixes').pipe(
      map((res) => {
        if (res.success) {
          return res.result.map((e) => {
            return { id: e.id, description: e.description };
          });
        } else {
          return [];
        }
      })
    );
  }

  getLegalRepresentatives() {
    return this.restService.get_api('individuals/top/1000').pipe(
      map((res) => {
        if (res.success) {
          return res.result.map((ind) => {
            return {
              ...ind,
              description: `${ind.name} ${ind.last_name ? ind.last_name : ''}`,
            };
          });
        } else {
          return [];
        }
      })
    );
  }

  getPowersByIndividual(id) {
    return this.restService
      .get_api(`power/GetCompaniesByIndividual/${id}`)
      .pipe(
        map((res) => {
          if (res.success) {
            return res.result;
          } else {
            return null;
          }
        })
      );
  }

  getExcelIndividual(
    id,
    name,
    options: {
      authorities: boolean;
      powers: boolean;
      stock: boolean;
    }
  ) {
    const path = `excel/${id}?authorities=${options.authorities}&powers=${options.powers}&stock=${options.stock}`;
    const dateFormatted = this.getTodayFormattedDate();
    const formattedName = `${name.replace(
      /\s/g,
      '_'
    )}_report_${dateFormatted}.xlsx`;

    this.restService.setPath('individuals_company');
    return this.restService.downloadFile(
      path,
      `${formattedName}`,
      'application/vnd.openxmlformats-officedocument'
    );
  }

  getCompanyExcel(options: {
    authorities: boolean;
    powers: boolean;
    stock: boolean;
  }) {
    const path = `excel?authorities=${options.authorities}&powers=${options.powers}&stock=${options.stock}`;
    const dateFormatted = this.getTodayFormattedDate();
    const formattedName = `full_report_${dateFormatted}.xlsx`;

    this.restService.setPath('individuals_company');
    return this.restService.downloadFile(
      path,
      `${formattedName}`,
      'application/vnd.openxmlformats-officedocument'
    );
  }

  getTodayFormattedDate() {
    const today = new Date();
    const formatter = new Intl.DateTimeFormat('es', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    const dateFormatted = formatter.format(today);
    return dateFormatted.replace(/\s/g, '').replace(/[\/,:]/g, '_');
  }

  getIndividuals(index, size, type, search_name, search_last_name) {
    return this.restService
      .get_api_by_params('individuals/list', {
        start: (index - 1) * size,
        limit: size,
        type: type,
        name: search_name,
        lastname: search_last_name,
      })
      .pipe(
        map((res) => {
          if (res.success) {
            return {
              total: res.total,
              result: res.result.map((e) => {
                return {
                  ...e,
                  fullname: `${e.name} ${e.last_name ? e.last_name : ''}`,
                  enableDelete:
                    e.powers === 0 && e.stock === 0 && e.authorities === 0,
                };
              }),
            };
          } else {
            return {
              total: 0,
              result: [],
            };
          }
        })
      );
  }

  deleteIndividual(id: string, signer = false): Observable<any> {
    const idToDelete = signer ? `${id}/force/true` : id;
    return this.restService.delete_from('individuals/delete', idToDelete);
  }

  getCountries() {
    return this.restService.get_api(`countries/list`).pipe(
      map((res) => {
        if (res.success) {
          return res.result.map((e) => {
            return {
              lang_code: `COUNTRIES.ID${e.id}`,
              description: e.name,
              id: e.id,
            };
          });
        } else {
          return [];
        }
      })
    );
  }
}
